.jobs_container_title{
    background: white;
    padding: 18px 20px 20px 30px;
    border-radius: 3px;
    z-index: 9;
    position: relative;
}
.jobs_container_content{
    background: white;
    padding: 48px 20px 20px 30px;
    border-radius: 3px;
    z-index: 9;
    position: relative;
    margin-left: 20;
}
.section-title h6 {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 2rem;
    font-weight: 500;
    position: relative;
    margin: 0;
}
.item-jobpost {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #c9c9c9;
    color: #9e9e9e;
}
.heading {
    color: #377599;
    font-size: 22px;
    font-weight: 410;
    font-family:Georgia, 'Times New Roman', Times, serif
}
.list-inline {
    padding-left: 0;
    list-style: none;
}
.list-inline label {
    display: inline-block;
    padding: 0.2em 0.6em 0.3em;
    font-size: 75%;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    margin-bottom: 0.5rem;
}
h5{
    font-weight: 600 !important;
    margin-bottom: 0.3em;
    font-weight: 540;
    line-height: 1.1;
}
b{
    font-weight: 600;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.bg-green {
    background-color: #eaeaea !important;
    color: #000 !important;
    font-size: 11px;
}