.container-card{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* background-color: #74EbD5; */
    /* background-image: linear-gradient(90deg,#74ebd5 0%,#9face6 100%); */
 }
.box{
    width:300px;
    height: 455px;
    background-color: #fff;
    border-radius: 5px;
    padding:20px;
    padding-top: 80px;
    margin: 20px;
    text-align: center;
    letter-spacing: 1px;
    box-shadow: 0 3px 5px rgba(0,0,0,.3);
    overflow: hidden;
    position: relative;
    z-index: 0;
} 
.box h3{
    font-size: 25px;
    padding: 20px 0;
    color: #444;
} 
.box p{
    font-size: 15px;
    color: #777;
}
.box button{
    width: 140px;
    height: 40px;
    background-color: #333;
    color: #fff;
    border: none;
    outline: none;
    font-size: 17px;
    cursor: pointer;
    border-radius: 50px;
    margin-top: 27px;
}
.box button:hover{
    letter-spacing: 2px;
}
.box:beofre{
    width: 100%;
    height:100%;
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.box::after{
    width: 100%;
    height: 100%;
    background-color: #fbda61;
    background-image: linear-gradient(45deg,#FBDA61 0%,#FF5ACD 100%);
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    clip-path: circle(20% at 90% 10%);
    transition: .2s;
}
/* .box .count{
    position: absolute;
    top: 15px;
    right: 30px;
    font-size: 50px;
    font-weight:bolder ;
     color: #fff;   
} */
.box:nth-child(1):after{
    background-color: #fbda61;
    background-image: linear-gradient(45deg,#FBDA61 0%,#FF5ACD 100%);
}
.box:nth-child(2):after{
    background-color: #0093e9;
    background-image: linear-gradient(150deg, #0093e9 0%, #80d0c7 100%);
}
.box:nth-child(3):after{
    background-color: #08aeea;
    background-image: linear-gradient(0deg, #08aeea 0%, #2af598 100%);
}
.box:nth-child(4):after{
    background-color: #ffc371;
    background-image: linear-gradient(45deg, #ffc371 0%, #ff5f6d 100%);
}
.box:nth-child(5):after{
    background-color: #b565a7;
    background-image: linear-gradient(150deg, #b565a7 0%, #ff6f91 100%);
}

.box:hover::after{
    clip-path: circle(100%);
}
.box:hover h3{
    color: #fff;
}
.box:hover p{
    color: #fff;
}
.box:hover button{
    color: #333;
    background-color: #fff;
}
.box:hover ::before{
    background-color: rgba(0, 0,0,);
}
