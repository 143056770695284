
.chatbot-toggle {
  position: fixed;
  bottom: calc(1rem + 5px); /* Adjust this value as needed */
  right: calc(7rem + 1px); /* Adjust this value as needed */
  background-color: #06A3DA;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 100; /* Ensure it's above the chat container */
}

.chatbot-toggle.active {
  background-color: #0485B9;
}

.chatbot-toggle:hover {
  background-color: #0485B9;
}
  .chat-container {
    max-width: 25vw;
    min-width: 25vw;
    margin: 20px auto;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    position: fixed;
    bottom: 3rem;
    right: 7rem;
    z-index: 99;
  }
  
  .chat-header {
    background-color: #06A3DA;
    color: white;
    padding: 10px;
    text-align: center;
  }
  
  .chat-messages {
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
  }
  
  .message {
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
  }
  
  .user {
    background-color: #ffffff;
    color: #333;
    text-align: right;
  }
  
  .bot {
    background-color: #06A3DA;
    color: white;
    text-align: left;
  }
  
  .chat-input {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #e0e0e0;
  }
  
  .chat-input input {
    flex: 1;
    padding: 5px;
    border: none;
    border-radius: 3px;
    margin-right: 5px;
  }
  
  .chat-input button {
    background-color: #06A3DA;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .chat-input button:hover {
    background-color: #0485B9;
  }
  