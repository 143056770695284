
.form-container {
  max-width: 600px;
  margin: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.form-container .close-button {
  position: initial;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: #444;
  font-size: 24px;
  border: none;
  cursor: pointer;
}
.form-container .close-button:hover {
  color: #777;
}
.form-container h1 {
  text-align: center;
  margin-bottom: 20px;
  font-family: "Georgia", serif;
  font-size: 28px;
  color: #3080ca;
  font-weight: normal;
}

.form-container .form-group {
  margin-bottom: 20px;
}

.form-container label {
  display: block;
  font-weight: bold;
  margin-bottom: 3px;
  color: #444444;
}

.form-container input,
.form-container textarea,
.form-container select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  color: #333;
  transition: border-color 0.2s ease-in-out;
}
.form-container input:hover,
.form-container textarea:hover,
.form-container select:hover {
  border-color: #555353; 
}

.form-container select {
  cursor: pointer;
}

.submit-buttom{
  background-color: #4ba7b8;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.submit-buttom:hover{
  background-color: #4586a0;
}
#years-of-experience {
  width: 100%;
}
.overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: rgba(14, 13, 13, 0.3);
  backdrop-filter: blur(2px); 
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
  box-shadow: 0 4px 8px rgba(59, 33, 33, 0.9);
}